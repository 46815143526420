// 拼接附件函数
export default (data) => {
  let picUrl = ''
  let baseUrl = 'http://apisem.ucas.ac.cn/'
  data.length>0?picUrl+='<h3 style="margin:30px 0 0 0">File download：</h3>':''
  data.forEach(element => {
    picUrl += `
    <div style="margin:5px 0 0 0">
      <span style="color:#2966bf;font-size:18px">
        ${element.name}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button  onclick='window.open("${baseUrl+element.url}")' style="background-color:#2966bf;border:none;color:#fff;border-radius:5px;padding:5px 8px;cursor: pointer;hover{color:red}">Download files</button>
        </span>
      </div>
    `
  });
  return picUrl
}