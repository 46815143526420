<template>
  <div class="xszj_box" ref="xszj_box">
    <h1>{{ h1 }}</h1>
    <div class="nav_list" v-show="show">
      <ul>
        <li v-for="(item,i) in wz_List.list" :key="i" @click="pushArticle_xq(item)">
          <img :src="item.is_top==1?'https://xq-images.obs.cn-north-4.myhuaweicloud.com/2022070618117Tx22xh':item.is_new==1?'https://xq-images.obs.cn-north-4.myhuaweicloud.com/2022070618150nKswsR':'https://xq-images.obs.cn-north-4.myhuaweicloud.com/2022070618259YxAm28'" :class="item.is_top==1||item.is_new==1?'top_top_new':'top_no_topNew'">
          <span>{{item.title_en}}</span>
          <a>{{item.created_at&&item.created_at.slice(0,10)}}</a>
        </li>
      </ul>
      <!-- 底部分页 -->
      <div class="pagination">
          <Pagination :total="from.total" :page="from.page" :limit="from.limit" @pageChange="pageChanges" :en="en"/>
      </div>
    </div>

    <div v-show="from.is_lxwm==1">
      <div class="mapBox" ref="mapBox">
        <div id="container"></div>
        <nav v-html="mapData.data.content_en"></nav>
      </div>
    </div>
    <div v-show="from.is_lxwm==0">
      <nav v-html="wz_danpian" v-show="show_nav" class="navs_wzList"></nav>
    </div>

  </div>
</template>

<script setup>
import Pagination from '@/components/Pagination.vue'
import * as axios from '@/api/API.js'
import miiter from '@/utils/miitbus.js'
import{ useRoute,useRouter,onBeforeRouteLeave,stopWatchRoute } from 'vue-router'
import {ref,reactive,watch,onMounted,nextTick,onDeactivated,destroyWatch, onUnmounted} from 'vue'
import usedownloadAttachments from '@/hooks/usedownloadAttachments.js'
import usedownloadAttachmentsen from '@/hooks/usedownloadAttachmentsen.js'
import * as http from "@/api/API";
const route = useRoute()
const router = useRouter()
const h1 = ref('Article List')
const wz_danpian = ref('')
const show = ref(true)
const show_nav = ref(true)
const wz_List = reactive({
  list: [],
  xszj_data1: {},
  xszj_data2: {},
})
const en = ref(true)
const from = reactive({
  page:1,
  limit:15,
  total:0,
  type_id:route.params.id,
  is_en:1,
  is_lxwm:0
})



const mapBox = ref(null)
const mapData = reactive({
  data:{}
})


const islab = () => {
  if(route.params.isen){
    from.is_en = route.params.isen
    en.value = false
  }
}
islab()
// 获取文章列表
const getwz_List = async () => {
  const {data:res} = await axios.getHttp("/api/article",from)
  if(res.code == 200){
    if(res.data.total == 1){
      h1.value = res.data.data[0].title_en
      show.value = false
      show_nav.value = true
      console.log(route.params.isen)
      if(route.params.isen){
        wz_danpian.value = res.data.data[0].content_en?res.data.data[0].content_en + usedownloadAttachments(res.data.data[0].pic_url):''
      }else{
        wz_danpian.value = res.data.data[0].content_en?res.data.data[0].content_en + usedownloadAttachmentsen(res.data.data[0].pic_url):''
      }

      if(res.data.data[0].type_id==316){
        from.is_lxwm=1;
      }else{
        from.is_lxwm=0;
      }
    }
    else{
      show.value = true
      show_nav.value = false
      wz_List.list = res.data.data
      from.total = res.data.total
      h1.value = res.data.type_name
    }
  }
}
getwz_List()
// 分页
const pageChanges = (ee) => {
  from.page = ee
  getwz_List()
}

const xszj_box = ref(null)
onMounted(() => {
  mapBox.value.style.opacity = 0
  var map = new AMap.Map('container', {
    zoom: 20,//级别
    center: [116.330494, 39.982166],//中心点坐标
    viewMode: '3D',//使用3D视图
    lang:'en'
  });
  // 创建一个 Marker 实例：
  var marker = new AMap.Marker({
    position: new AMap.LngLat(116.330494, 39.982166),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
    title: '北京'
  });
  // 将创建的点标记添加到已有的地图实例：
  map.add(marker);
  var infoWindow = new AMap.InfoWindow({
    anchor: 'top-left',
    content: 'Zhongguancun Campus (Teaching Building) of Chinese Academy of Sciences',
  });
  infoWindow.open(map, [116.330494, 39.982166])
  mapBox.value.style.transform = 'translateY(500px)'
  getLxlist()
})
// 点击li跳转到详情
let pushArticle_xq = (e) => {
  // 如果有外部链接就跳转
  if(e.jump_url){
    window.open(e.jump_url)
    return
  }
  router.push({
    name: route.params.isen == '2'?'Moearticlexq':'Enarticlexq',
    params:{
      articleid:e.id,
      id:route.params.id,
      tid:route.params.tid,
      level:route.params.level,
      isen:route.params.isen
    }
  })
}





miiter.on('onEnactilcetap',() => {
    setTimeout(() => {
      islab()
      from.type_id = route.params.id
      getwz_List()
    })
})

// 获取联系我们的数据
let getLxlist = async (id) => {
  const {data:res} = await axios.getHttp("/api/article",from)
  if(res.code == 200){
    mapData.data = res.data.data[0]
    setTimeout(() => {
      mapBox.value.style.opacity = 1
      mapBox.value.style.transform = 'translateY(0)'
    },800)
  }
}

</script>

<style lang="less" scoped>
@maxw:128rem;
.mapBox{
  margin: 0;
  padding: 0;
  transition: ease 1s;
}
#container {
  width: 80%;
  margin:20px auto 0 auto;
  height: 500/@maxw;
}
.xszj_box{
  box-sizing: border-box;
  width: 100%;
  transition: ease .8s;
  >h1{
    font-size: 39/@maxw;
    font-family: Georgia;
    font-weight: bolder;
    color: #174994;
    border-bottom:2/@maxw solid #174994;
    width: 100%;
    padding: 0 0 10/@maxw 0;
    text-align: center;
    margin: 0;
  }
  .top_two{
    width: 100%;
    >div{
      >.gg_sty{
      width: 100%;
      height: 140/@maxw;
      position: relative;
      background-image: url("../../../../assets/ejxsjzbj2.png");
      background-size: 100%;
      background-repeat: no-repeat;
      margin: 30/@maxw 0 30/@maxw 0;
      display: flex;
      align-items: center;
      transition: ease .8s;
      cursor: pointer;
      .left_time{
        width: 100/@maxw;
        height: 100/@maxw;
        margin: 0 0 0 50/@maxw;
        border: 1px solid #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        span{
          font-size: 55/@maxw;
          font-family: Source Han Sans CN-Medium;
          font-weight: 500;
        } 
        a{ 
          margin-top: -8/@maxw;
          font-size: 17/@maxw;
          font-family: Source Han Sans CN-Medium;
          font-weight: 500;
        }
      }
      .right_text{
        color: #fff;
        display: flex;
        align-items: center;
        flex: 1;
        height: 95/@maxw;
        margin: 0 300/@maxw 0 50/@maxw;
        font-size: 22/@maxw;
        font-family: Source Han Sans CN-Medium;
        font-weight: 500;
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:3; 
      }
    }
    .new{
     position:absolute;
     width: 86/@maxw;
     height: 28/@maxw;
     right: 33/@maxw;
     top: 30/@maxw;
     background-image: url("../../../../assets/ejnew2.png");
    background-size: 100% 100%;
    }
    .gg_sty:hover{
      background-image: url("../../../../assets/ejxsjzbj1.png");
      .new{
        width: 86/@maxw;
        height: 28/@maxw;
        position: absolute;
        right: 33/@maxw;
        top: 30/@maxw;
        background-image: url("../../../../assets/ejnew1.png");
        background-size: 100% 100%;
        z-index: 9999;
      }
    }
  }
    }
  .nav_list{
    width: 100%;
    margin: 30/@maxw 0 0 0;
    font-family: 'Microsoft YaHei';
    >ul{
      margin: 0;
      padding: 0;
      >li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 50/@maxw;
        margin: 10/@maxw 0;
        border-bottom: 1/@maxw dotted #ccc;
        transition: ease .3s;
        padding: 0 0 0 10/@maxw;
        cursor: pointer;
        .top_top_new{
          width: 40/@maxw !important;
          height: 45%;
        }
        .top_no_topNew{
          margin: 0 0 0 10/@maxw;
          width: 11/@maxw !important;
          height: 35%;
        }
        >span{
          flex: 1;
          margin: 0 15/@maxw 0 30/@maxw;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          font-size: 22/@maxw;
          font-family: Source Han Sans CN-Medium;
          font-weight: 500;
          color: #5A5A5A;
        }
        >a{
          text-align: right;
          width: 150/@maxw;
          white-space: nowrap;
          font-family: 'Microsoft YaHei';
          font-size: 18/@maxw;
          margin-right: 20/@maxw;
        }
      }
      >li:hover{
        box-shadow: 0 0 8/@maxw rgb(90, 90, 90);
        margin-left: 3/@maxw;
      }
    }
  }
}
.pagination{
  display: flex;
  justify-content: center;
  margin: 6% 0 0 0; 
}
.navs_wzList{
  width: 100%;
  /deep/ p,/deep/ div,/deep/ span{
      font-size: 18px;
      font-family: '微软雅黑';
      line-height: 1.5;
      color: #5A5A5A;
     }
}
@media screen and (max-width:750px) {
  @maxw:50rem;
  .xszj_box{
>h1{
    font-size: 39/@maxw;
    border-bottom:2/@maxw solid #174994;
    padding: 0 0 10/@maxw 0;
  }
  .top_two{
    >div{
      >.gg_sty{
      height: 140/@maxw;
      margin: 30/@maxw 0 30/@maxw 0;
      .left_time{
        width: 100/@maxw;
        height: 100/@maxw;
        margin: 0 0 0 50/@maxw;
        span{
          font-size: 55/@maxw;
        } 
        a{ 
          margin-top: -8/@maxw;
          font-size: 17/@maxw;
        }
      }
      .right_text{
        height: 95/@maxw;
        margin: 0 300/@maxw 0 50/@maxw;
        font-size: 22/@maxw;
      }
    }
    .new{
     width: 86/@maxw;
     height: 28/@maxw;
     right: 33/@maxw;
     top: 30/@maxw;
    }
    .gg_sty:hover{
      .new{
        width: 86/@maxw;
        height: 28/@maxw;
        right: 33/@maxw;
        top: 30/@maxw;
      }
    }
  }
    }
  .nav_list{
    margin: 30/@maxw 0 0 0;
    >ul{
      >li{
        height: 50/@maxw;
        margin: 10/@maxw 0;
        border-bottom: 1/@maxw dotted #ccc;
        .top_top_new{
          width: 40/@maxw !important;
        }
        .top_no_topNew{
          width: 11/@maxw !important;
        }
        >span{
          margin: 0 15/@maxw 0 20/@maxw;
          font-size: 22/@maxw;
        }
        >a{
          width: 150/@maxw;
          font-size: 18/@maxw;
          margin-right: 5/@maxw;
        }
      }
      >li:hover{
        box-shadow: 0 0 8/@maxw rgb(90, 90, 90);
        margin-left: 3/@maxw;
      }
    }
  }
}
}
</style>